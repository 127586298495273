
.container-section-three  {
    background-color: #2e2e35;
    display: flex;
    justify-content: center;
    width: 100%;
}

.portfolio-section {
    height: 50%;
    margin-top: 8rem;
    width: 35%;
}

.portfolio-title-subtitle-text {
    display: flex;
    justify-content: center;
}

.h3-portfolio {
    color: #e4aa48;
    font-size: 1rem;
}

.h2-subtitle {
    color: #dadada;
    font-size: 2.5rem;
    text-align: center;
}

.p-paragraph-text {
    color: #dadada;
    line-height: 1.5rem;
    margin: 1rem 0rem 3rem 0rem;
    text-align: center;
}

.navbar-three-section {
    display: flex;
    justify-content: space-between;
}

.nav-link-three-section {
    color: #959595;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
}

.nav-link-three-section:hover {
    color: #e4aa48;
}

.container-cards-projects {
    background-color: #2e2e35;
    display: flex;
    justify-content: center;
    width: 100%;
}

.cards-projects-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 70%;
}

@media screen and (max-width: 480px) {

    .portfolio-section {
        margin-top: 4rem;
        width: 100%;
    }

    .h2-subtitle {
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    .p-paragraph-text {
        margin: 1rem 1rem 3rem 1rem;
    }
    
}