
footer {
    background-color: #2e2e35;
    height: 80%;
    width: 100%;
}
.container-footer {
    background-color: #2e2e35;
    padding-top: 3rem;
    width: 100%;
}

.hr-footer {
    border: 1px solid #e4aa48;
    top: 5rem;
    width: auto;
}

.footer-contains {
    align-items: center;
    display: flex;
    justify-content: center;
}

.p-footer {
    color: #dadada;
    font-weight: 400;
    margin: 1rem;
    text-align: center;
}
