.container-section-two {
    background-color: #34343b;
    height: auto;
    width: 100%;
}

/* ---------------- Services section styles --------------- */

.services-title-container {
    display: flex;
    justify-content: center;
}

.h4-services-title {
    color: #e4aa48;
    margin-top: 8rem;
}

.services-subtitle-container {
    display: flex;
    justify-content: center;
}

.h2-subtitle-services {
    color: #dadada;
    font-size: 2rem;
    margin-top: 1rem;
}

.services-paragraph-container {
    display: flex;
    justify-content: center;
}

.p-services-paragraph {
    color: #dadada;
    margin: 1rem 0rem 5rem 0rem;
    text-align: center;
    width: 40%;
}

/* ---------------- Services cards styles --------------- */

.services-cards {
    background-color: #34343b;
    display: flex;
    justify-content: center;
    width: 100%;
}
.container-services-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 80%;
}

.container-card {
    background-color: #2e2e35;
    height: 18rem;
    margin: 0rem 0rem 5rem 0rem;
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 18rem;
    justify-content: center;
}

.container-img-card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-card-stack {
    width: 50%;
    transition: transform .3s; 
}

.img-card-stack:hover{
    transform: scale(1.2); 
}

.h4-card-subtitle {
    color: #dadada;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
}

.h4-card-subtitle:hover {
    color: #e4aa48;
    transition: .5s;
}

.info-card {
    color: #dadada;
    margin-top: 0.5rem;
}

.info-card:hover {
    color: #e4aa48;
    transition: .5s;
}

@media screen and (max-width: 480px) {

    .h4-services-title {
        margin-top: 4rem;
    }

    .p-services-paragraph {
        line-height: 1.5rem;
        margin: 1rem 0rem 3rem 0rem;
        width: 90%;
    }

    .container-services-cards {   
        width: 100%;
    }
    
    .container-card {
        margin: 0rem 0rem 1.5rem 0rem;
        width: 90%;
    }

}
