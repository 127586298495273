* {
    margin: 0%;
    padding: 0%;
}

nav {
    background-color: #2e2e35;
    height: 6rem;
    width: 100%;
}

.container-img-header{
    width: 50%;
}

.img-header {
    width: 8%;
}

.ul-container{
    align-items: center;
    background-color: #2e2e35;
    display: flex;
    float: right;
    height: 6rem;
    justify-content: space-evenly;
    list-style: none;
    width: 100%;   
}

.nav-link {
    color: #dadada;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin: 0.5rem 0rem 0.5rem 0rem;
    text-decoration: none;
}

.nav-link::after {
    background: #e4aa48;
    content: '';
    display: block;
    height: 2px;
    transition: width .3s;
    width: 0;
}

.nav-link:hover::after {
    width: 100%;
} 

.social-media-header {
    display: flex;
    justify-content: space-evenly;
}

.container-mobile-menu {
    display: none;
}

.container-img-header-mobile {
    display: none;
}

@media screen and (max-width: 995px) {

    nav {
        height: 2rem;
    }

    .container-mobile-menu {
        background-color: #2e2e35;
        display: block;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .mobile-menu {
        margin-right: 1rem;
    }
    
    .nav-mobile-menu {
        height: auto;
    }

    .nav-link-mobile { 
        color: #dadada;
        font-weight: bold;
    }

    .ul-container {
        display: none;
    }
    
    .container-img-header-mobile {
        display: block;
    }

    .img-header-mobile {
        display: block;
        margin-left: 1rem;
        padding-top: 0%;
        width: 15%;
    }
    
    .ul-mobile-menu {
        align-items: center;
        background-color: #333;
        display: flex;
        flex-direction: column;
        height: 18rem;
        justify-content: center;
        list-style: none;
        margin: 0.5rem 1rem 0px 1rem;
    }
}
