.container-contact-me-section {
    background-color: #2e2e35;
    height: 50%;
    width: 100%;
}

.container-contact-info  {
    display: flex;
    justify-content: center;
    width: 100%;
}

.contact-section {
    height: 50%;
    margin-top: 8rem;
    width: 35%;

}

.contact-title-subtitle-text {
    display: flex;
    justify-content: center;
}

.contact-title-subtitle-text-p {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.h3-contact-title {
    color: #e4aa48;
    font-size: 1rem;
}

.h2-subtitle {
    color: #dadada;
    font-size: 2.5rem;
    text-align: center;
}

.p-paragraph-text {
    color: #dadada;
    line-height: 1.5rem;
    margin: 1rem 0rem 3rem 0rem;
    text-align: center;
}

.container-contact-box {
    background-color: #2e2e35;
    display: flex;
    height: 80%;
    justify-content: center;
    width: 100%;
}
.contact-box {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 60%;
}

.box {
    align-items: center;
    background-color: #36363e;
    display: flex;
    height: 4rem;
    justify-content: center;
    margin: 0rem 0rem 2rem 0rem;
    width: 20rem;
}

.contact-h3 {
    color: #e4aa48;
    cursor: pointer;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 500;
    text-decoration: none;
    margin-left: 0.5rem;
}

.contact-h3::after {
    background: #e4aa48;
    content: '';
    display: block;
    height: 2.5px;
    transition: width .7s;
    width: 0;
}

.contact-h3:hover::after {
    width: 100%;
} 

.btn-top {
    display: flex;
    justify-content: flex-end;
    margin-right: 0rem 5rem 5rem 0rem;
    width: 100%;
}

@media screen and (max-width: 480px) {
    .contact-section {
        margin-top: 6rem;
        width: 100%;
    }
    .h2-subtitle {
        font-size: 1.5rem;
    }
    .p-paragraph-text {
        line-height: 1.5rem;
    }
    .box {   
        height: 3rem;
        justify-content: center;
        margin: 0rem 0rem 1.5rem 0rem;
    }
    .contact-h3 {
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;
    }
}
