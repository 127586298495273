.container-section-one {
  background-color: #2e2e35;
  width: 100%;
  height: 100%;
  padding-top: 2rem;
}

.columns-section-one {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: space-evenly;
  width: 100%;
}

/* ---------------- First column styles --------------- */

.first-column-container{
  margin-top: 7rem;
}

.h1-name-title {
  color: #dadada;
  font-size: 3.5rem;
  line-height: 3.5rem;
}

.span-final-point-name {
  color: #e4aa48;
}

.hr-first-column {
  border: 1px solid #e4aa48;
  margin-top: 2rem;
  width: 30%;
}

.social-media { 
  margin-top: 1rem;
}

.social-media-first-column {
  display: flex;
  flex-direction: row;
}

.a-social-media-action {
  color: #dadada;
  font-weight: bold;
  text-decoration: none;
  transition: 0.5s;
}

.a-social-media-action:hover {
  color: #e4aa48;
}

.p-social-links{
  color: #dadada;
  margin: 0rem 0.5rem 0rem 0.5rem;
}

.container-btn-contact-me{
  margin-top: 3rem;
}

.btn-contact-me {
  background-color: #2e2e35;
  border: 2px solid #e4aa48;
  color: #e4aa48;
  font-size: 1rem;
  font-weight: bold;
  height: 3rem;
  transition: 0.5s;
  width: 10rem;
}

.btn-contact-me:hover {
  background-color: #e4aa48;
  color: #131315;
}
/* ---------------- Second column styles --------------- */

.img-second-column {
  margin-top: 2rem;
  width: 100%;
}
/* ---------------- Third column styles --------------- */

.third-column-container {
  margin-top: 6.5rem;
}

.h3-introduction-title {
  color: #e4aa48;
  font-size: 1rem;
}

.h2-profession-title{
  color: #dadada;
  font-size: 2.5rem;
}

.p-personal-description {
  color: #dadada;
  font-family: 'Nunito', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  margin-top: 1rem;
  width: 20rem;
}

.container-learn-action {
  margin-top: 1rem;
  width: 7rem;
}

.learn-more-action{
  color: #e4aa48;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
}

.learn-more-action::after {
  background: #e4aa48;
  content: '';
  display: block;
  height: 2px;
  transition: width .3s;
  width: 0;
}

.learn-more-action:hover::after{
  width: 95%;
}

@media screen and (max-width: 480px) {

  .container-section-one {
    height: 25rem;
  }
  
  .first-column-container{
    margin-top: 3rem;
    margin-left: 1rem;
  }

  .second-column-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .h1-name-title {
    font-size: 2rem;
    line-height: 2.5rem; 
  }

  .columns-section-one {
    display: flex;
    justify-content: flex-start;
  }

  .img-second-column {
    margin: 1rem 0rem 0rem 1rem;
    width: 100%;
  }

  .third-column-container {
    display: none;
  }

}

@media screen and (max-width: 382px) {

  .first-column-container{
    width: 30%;
  }

  .h1-name-title {
    font-size: 1.5rem;
    line-height: 1.5rem; 
  }

  .img-second-column {
    margin: 2rem 1rem 0rem 0rem;
    width: 100%;
  }
  .second-column-container {
    width: 60%;
  }
  .social-media-first-column {
    flex-direction: column;
  }

}

@media screen and (max-width: 352px) {

  .first-column-container{
    width: 35%;
  }
  
  .h1-name-title {
    font-size: 2rem;
    line-height: 2.5rem; 
  }

  .img-second-column {
    margin: 4rem 0rem 0rem 0rem;
    width: 100%;
  }

  .second-column-container {
    width: 60%;
  }
  .social-media-first-column {
    flex-direction: column;
  }

}